import request from '@/utils/request';

export function createBadgeTask(data) {
  return request({
    url: 'v1/badge/create',
    method: 'post',
    data,
  });
}

export function fetchParam(id) {
  return request({
    url: `v1/badge/${id}`,
    method: 'get',
  });
}

export function fetchList() {
  return request({
    url: 'v1/badge/list',
    method: 'get',
  });
}
