<template>
  <div>
    <el-upload
      :action="uploadApiUrl"
      list-type="picture-card"
      :file-list="inputList"
      :headers="headers"
      :before-upload="beforeUpload"
      :on-remove="handleRemove"
      :on-success="handleUploadSuccess"
      :on-preview="handlePreview"
      multiple
      :limit="maxCount"
      :on-exceed="handleExceed"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import { getToken } from '@/utils/auth';

const token = getToken();
export default {
  name: 'multiUpload',
  props: {
    value: Array,
    inputList: Array,
    isUploading: Boolean,
    maxCount: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      dataObj: {
        policy: '',
        signature: '',
        key: '',
        ossaccessKeyId: '',
        dir: '',
        host: '',
      },
      fileList: [],
      dialogVisible: false,
      dialogImageUrl: null,
      uploadApiUrl: process.env.VUE_APP_OSS_API,
    };
  },
  watch: {
    inputList(newValue) {
      this.fileList = JSON.parse(JSON.stringify(newValue));
    },
  },
  computed: {
    headers() {
      return {
        Authorization: getToken(),
      };
    },
  },
  methods: {
    emitInput(fileList) {
      const value = [];
      for (let i = 0; i < fileList.length; i += 1) {
        value.push(fileList[i].url);
      }
      this.$emit('input', value);
    },
    handleRemove(file) {
      const url = file.url.startsWith('blob:') ? file.response.url : file.url;
      for (let i = 0; i < this.fileList.length; i += 1) {
        if (this.fileList[i].url == url) {
          this.fileList.splice(i, 1);
          break;
        }
      }
      this.emitInput(this.fileList);
    },
    handlePreview(file) {
      this.dialogVisible = true;
      this.dialogImageUrl = file.url;
    },
    beforeUpload(file) {
      this.$emit('update:isUploading', true);
      return true;
    },
    handleUploadSuccess(res, file) {
      const { url } = res;
      this.fileList.push({ url });
      this.emitInput(this.fileList);
      this.$emit('update:isUploading', false);
    },
    handleExceed(files, fileList) {
      this.$message({
        message: `最多只能上传${this.maxCount}张图片`,
        type: 'warning',
        duration: 1000,
      });
    },
  },
};
</script>
<style>
</style>
