<template>
  <div style="margin-top: 50px">
    <el-form ref="skuStockDetailForm" size="large">
      <div v-for="(item, index) in skuStockParams" v-bind:key="index">
        <el-card>
          <el-form-item label="SKU">
            <el-input v-model="item.sku" disabled>SKU</el-input>
          </el-form-item>
          <el-form-item label="Image">
            <el-radio-group v-model="item.imageUrl">
              <el-radio v-for="(imageParam, paramIndex) in value.imageAssetParams" size="small" style="width: 200px"
                :key="index + 'image_' + paramIndex" :label="imageParam.imageUrl">
                <img :src="imageParam.imageUrl" style="width: 180px" />
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="item.primary" @change="primaryChange(item.primary, index)">Is Primary</el-checkbox>
          </el-form-item>
          <el-card>
            中文Attribute
            <div v-for="(attr, attrIndex) in item.permutationsChs" v-bind:key="attrIndex">
              <el-select v-model="attr.key">
                <el-option v-for="attributeOption in productAttributeOptionsChs" :key="attributeOption"
                  :value="attributeOption" :lable="attributeOption">
                </el-option>
              </el-select>
              <el-select v-model="attr.value">
                <el-option v-for="attrValueOption in (attr.key == null ? [] : attrValueOptionsChs[attr.key])"
                  :key="attrValueOption" :value="attrValueOption" :label="attrValueOption">
                </el-option>
              </el-select>
              <el-button size="mini" type="danger" @click="item.permutationsChs.splice(attrIndex, 1)">Delete</el-button>
            </div>
            <el-button size="mini" @click="addEntry(item.permutationsChs);">Add</el-button>
          </el-card>
          <el-card>
            English Attribute
            <div v-for="(attr, attrIndex) in item.permutationsEng" v-bind:key="attrIndex">
              <el-select v-model="attr.key">
                <el-option v-for="attributeOption in productAttributeOptionsEng" :key="attributeOption"
                  :value="attributeOption" :lable="attributeOption">
                </el-option>
              </el-select>
              <el-select v-model="attr.value">
                <el-option v-for="attrValueOption in (attr.key == null ? [] : attrValueOptionsEng[attr.key])"
                  :key="attrValueOption" :value="attrValueOption" :label="attrValueOption">
                </el-option>
              </el-select>
              <el-button size="mini" type="danger" @click="item.permutationsEng.splice(attrIndex, 1)">Delete</el-button>
            </div>
            <el-button size="mini" @click="addEntry(item.permutationsEng);">Add</el-button>
          </el-card>
        </el-card>
      </div>
      <el-form-item style="text-align: center">
        <el-button size="medium" @click="handlePrev">Prev Step</el-button>
        <el-button type="primary" size="medium" @click="handleFinishCommit">Submit</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { fetchList as fetchAttrList } from '@/api/productAttr';

export default {
  name: 'SkuStockDetail',
  props: {
    value: Object,
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productAttributeOptionsChs: [],
      productAttributeOptionsEng: [],
      attrValueOptionsChs: [],
      attrValueOptionsEng: [],
      productAttrCate: null,
      skuStockParams: [],
    };
  },
  computed: {
    attrCateId() {
      if (this.value == null) return null;
      return this.value.productAttributeCategoryId;
    },
  },
  watch: {
    attrCateId(newValue) {
      if (newValue != null) this.getProductAttributes(newValue);
    },
    value(newValue) {
      this.skuStockParams = newValue.skuStockParams;
    },
  },
  methods: {
    addEntry(array) {
      array.push({ key: '', value: '' });
    },
    getProductAttributes(id) {
      fetchAttrList(id).then((response) => {
        const list = response.data.data;
        this.productAttrCate = list;
        const listChs = list.filter((a) => a.localization == 'CHINESE_SIMPLIFIED');
        const listEng = list.filter((a) => a.localization == 'ENGLISH');
        this.productAttributeOptionsChs = listChs.map((a) => a.name);
        this.productAttributeOptionsEng = listEng.map((a) => a.name);
        listChs.forEach((attr) => {
          this.attrValueOptionsChs[attr.name] = attr.attributeValues;
        });
        listEng.forEach((attr) => {
          this.attrValueOptionsEng[attr.name] = attr.attributeValues;
        });
      });
    },
    primaryChange(newValue, index) {
      if (!newValue) return;
      for (let i = 0; i < this.value.skuStockParams.length; i += 1) {
        if (i == index) continue;
        this.value.skuStockParams[i].primary = false;
      }
    },
    handlePrev() {
      this.$emit('prevStep', this.skuStockParams);
    },
    handleFinishCommit() {
      this.$emit('finishCommit', this.skuStockParams);
    },
  },
};
</script>

<style scoped>
</style>
