import request from '@/utils/request';

export function fetchList(cid, params) {
  return request({
    url: `v1/productAttribute/listByCategory/${cid}`,
    method: 'get',
    params,
  });
}

export function deleteProductAttr(id) {
  return request({
    url: `v1/productAttribute/delete/${id}`,
    method: 'delete',
  });
}

export function createProductAttr(data) {
  return request({
    url: 'v1/productAttribute/create',
    method: 'post',
    data,
  });
}

export function updateProductAttr(id, data) {
  return request({
    url: `v1/productAttribute/update/${id}`,
    method: 'patch',
    data,
  });
}
export function getProductAttr(id) {
  return request({
    url: `v1/productAttribute/${id}`,
    method: 'get',
  });
}
