import axios from 'axios';

export async function translateToEnglish(src) {
  const request = {
    model: 'gpt-3.5-turbo-instruct',
    prompt: `Translate this to English:\n${src}`,
    temperature: 0,
    max_tokens: 2000,
  };
  const resp = await axios.request({
    method: 'POST',
    url: 'https://api.openai.com/v1/completions',
    headers: {
      Authorization: 'Bearer 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',
    },
    data: request,
  });
  return resp;
}
