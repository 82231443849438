<template>
  <div>
    <el-upload
      :action="uploadApiUrl"
      :data="null"
      list-type="picture"
      :headers="headers"
      :multiple="false"
      :file-list="fileList"
      :before-upload="beforeUpload"
      :on-remove="handleRemove"
      :on-success="handleUploadSuccess"
      :on-preview="handlePreview"
    >
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">
        不超过10MB
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="fileList[0].url" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import { getToken } from '@/utils/auth';

export default {
  name: 'singleUpload',
  props: {
    value: String,
  },
  computed: {
    imageUrl() {
      return this.value;
    },
    imageName() {
      if (this.value != null && this.value !== '') {
        return this.value.substr(this.value.lastIndexOf('/') + 1);
      }
      return null;
    },
    fileList() {
      return [
        {
          name: this.imageName,
          url: this.imageUrl,
        },
      ];
    },
    headers() {
      return {
        Authorization: getToken(),
      };
    },
  },
  data() {
    return {
      dialogVisible: false,
      uploadApiUrl: process.env.VUE_APP_OSS_API,
    };
  },
  methods: {
    emitInput(val) {
      this.$emit('input', val);
    },
    handleRemove(file, fileList) {
      this.emitInput('');
    },
    handlePreview(file) {
      this.dialogVisible = true;
    },
    beforeUpload(file) {
      return true;
    },
    handleUploadSuccess(res, file) {
      this.fileList.pop();
      const { url } = res;
      this.fileList.push({ name: file.name, url });
      this.emitInput(this.fileList[0].url);
    },
  },
};
</script>
<style>
</style>
