import request from '@/utils/request';

export function fetchList(parentId, params) {
  return request({
    url: '/v1/categories/list/',
    method: 'get',
    params,
  });
}

export function fetchOptions() {
  return request({
    url: '/v1/categories/listOptions',
    method: 'get',
  });
}
export function fetchListByParentId(parentId) {
  return request({
    url: `/v1/categories/list/${parentId}`,
    method: 'get',
  });
}

export function deleteProductCate(id) {
  return request({
    url: `/v1/categories/delete/${id}`,
    method: 'delete',
  });
}

export function createProductCate(data) {
  return request({
    url: '/v1/categories/create',
    method: 'post',
    data,
  });
}

export function updateProductCate(id, data) {
  return request({
    url: `v1/categories/update/${id}`,
    method: 'patch',
    data,
  });
}

export function getProductCate(id) {
  return request({
    url: `/v1/categories/${id}`,
    method: 'get',
  });
}
