import request from '@/utils/request';

export function fetchList(params) {
  return request({
    url: 'v1/products/list',
    method: 'get',
    params,
  });
}

export function fetchOptions(params) {
  return request({
    url: 'v1/products/listOptionsByMerchant',
    method: 'get',
    params,
  });
}

export function createProduct(data) {
  return request({
    url: 'v1/products/create',
    method: 'post',
    data,
  });
}

export function updateProduct(id, data) {
  return request({
    url: `v1/products/update/${id}`,
    method: 'patch',
    data,
  });
}

export function getProduct(id) {
  return request({
    url: `/product/updateInfo/${id}`,
    method: 'get',
  });
}

export function getProductParam(id) {
  return request({
    url: `v1/products/detailedReadableProduct/${id}`,
    method: 'get',
  });
}
export function deleteProduct(id) {
  return request({
    url: `v1/products/delete/${id}`,
    method: 'delete',
  });
}

export function updateProductStatus(data) {
  return request({
    url: 'v1/products/updateProductStatus',
    method: 'patch',
    data,
  });
}

export function supportBulk(id) {
  return request({
    url: `v1/products/supportBulk/${id}`,
    method: 'patch',
  });
}

export function disableBulk(id) {
  return request({
    url: `v1/products/disableBulk/${id}`,
    method: 'patch',
  });
}

export function setSkuStock(data) {
  return request({
    url: 'v1/products/setSkuStock',
    method: 'post',
    data,
  });
}
