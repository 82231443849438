import request from '@/utils/request';

export function fetchList(params) {
  return request({
    url: 'v1/merchants/list',
    method: 'get',
    params,
  });
}

export function fetchOptions(params) {
  return request({
    url: 'v1/merchants/listOptions',
    method: 'get',
    params,
  });
}

export function createMerchant(data) {
  return request({
    url: '/v1/merchants/create',
    method: 'post',
    data,
  });
}

export function deleteMerchant(id) {
  return request({
    url: `/v1/merchants/delete/${id}`,
    method: 'delete',
  });
}

export function getMerchant(id) {
  return request({
    url: `/v1/merchants/get/${id}`,
    method: 'get',
  });
}

export function updateMerchant(id, data) {
  return request({
    url: `/v1/merchants/update/${id}`,
    method: 'post',
    data,
  });
}

export function setPassword(data) {
  return request({
    url: '/v1/merchants/setPassword',
    method: 'post',
    data,
  });
}

export function setHours(id, data) {
  return request({
    url: `/v1/merchants/setHours/${id}`,
    method: 'post',
    data,
  });
}

export function setUsername(data) {
  return request({
    url: '/v1/merchants/setUsername',
    method: 'post',
    data,
  });
}

export function generateKeyChain(id) {
  return request({
    url: `/v1/merchants/generateKeychain/${id}`,
    method: 'post',
  });
}
