import request from '@/utils/request';

export function fetchList(pid, params) {
  return request({
    url: `v1/skuStocks/listByProductId/${pid}`,
    method: 'get',
    params,
  });
}

export function generateSku(params) {
  return request({
    url: 'v1/skuStocks/generate',
    method: 'get',
    params,
  });
}
