import request from '@/utils/request';

export function fetchList(params) {
  return request({
    url: 'v1/productAttribute/category/list',
    method: 'get',
    params,
  });
}

export function createProductAttrCate(data) {
  return request({
    url: 'v1/productAttribute/category/create/',
    method: 'post',
    data,
  });
}

export function deleteProductAttrCate(id) {
  return request({
    url: `v1/productAttribute/category/delete/${id}`,
    method: 'delete',
  });
}

export function updateProductAttrCate(id, data) {
  return request({
    url: `v1/productAttribute/category/update/${id}`,
    method: 'patch',
    data,
  });
}
export function fetchListWithAttr() {
  return request({
    url: 'v1/productAttribute/category/list/withAttr',
    method: 'get',
  });
}
