import request from '@/utils/request';

export function fetchListByMerchant(id) {
  return request({
    url: `v1/merchantMenu/byMerchantId/${id}`,
    method: 'get',
  });
}

export function createMerchantMenu(data) {
  return request({
    url: '/v1/merchantMenu/create',
    method: 'post',
    data,
  });
}

export function deleteMerchantMenu(id) {
  return request({
    url: `/v1/merchantMenu/delete/${id}`,
    method: 'delete',
  });
}

export function updateMerchantMenu(id, data) {
  return request({
    url: `/v1/merchantMenu/update/${id}`,
    method: 'patch',
    data,
  });
}
